import { faker } from '@faker-js/faker';
import isFactoryRandom from '@/mocks/isFactoryRandom';

export enum PRIORITY_LEVEL_NAME {
    LOW = 'low',
    MODERATE = 'moderate',
    CRITICAL = 'critical',
}

export default interface PriorityLevel {
    name: PRIORITY_LEVEL_NAME;
}

export function fakePriorityLevel(): PriorityLevel {
    return {
        name: isFactoryRandom
            ? faker.helpers.arrayElement([
                  PRIORITY_LEVEL_NAME.LOW,
                  PRIORITY_LEVEL_NAME.MODERATE,
                  PRIORITY_LEVEL_NAME.CRITICAL,
              ])
            : PRIORITY_LEVEL_NAME.CRITICAL,
    };
}
