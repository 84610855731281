import PriorityLevel, { fakePriorityLevel } from '@/types/PriorityLevel';
import NumericTrigger, { fakeNumericTrigger } from '@/types/NumericTrigger';
import { ActionConfiguration, fakeActionsConfiguration } from '@/types/ActionConfiguration';

export default interface AlertSetting {
    priority_level: PriorityLevel;
    triggerable: NumericTrigger;
    action_configurations: ActionConfiguration[];
}

export function fakeAlertSetting(): AlertSetting {
    return {
        priority_level: fakePriorityLevel(),
        triggerable: fakeNumericTrigger(),
        action_configurations: fakeActionsConfiguration(),
    };
}
