import { createRouter } from '@ionic/vue-router';
import { createWebHashHistory, RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue';
import { useUserStore } from '@/store/useUserStore';
import { useAlertStore } from '@/store/useAlertStore';

const onlyLoggedOut: string[] = ['/login', '/forgot-password', '/reset-password'];
const onlyLoggedIn: string[] = [
    '/tabs/no-alert',
    '/tabs/alert-infos',
    '/tabs/alert-map',
    '/tabs/alert-actions',
    '/tabs/alert-history',
    '/tabs/alert-meeting',
    '/profile',
];
const onlyNoAlert: string[] = ['/tabs/no-alert'];
const onlyActiveAlert: string[] = [
    '/tabs/alert-infos',
    '/tabs/alert-map',
    '/tabs/alert-actions',
    '/tabs/alert-history',
    '/tabs/alert-meeting',
];

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/no-alert',
    },
    {
        path: '/login',
        component: () => import('@/views/LoginPage.vue'),
    },
    {
        path: '/forgot-password',
        component: () => import('@/views/ForgotPasswordPage.vue'),
    },
    {
        path: '/reset-password',
        component: () => import('@/views/ResetPasswordPage.vue'),
    },
    {
        path: '/profile',
        component: () => import('@/views/ProfilePage.vue'),
    },
    {
        path: '/tabs/',
        component: TabsPage,
        children: [
            {
                path: '',
                redirect: '/tabs/no-alert',
            },

            {
                path: 'no-alert',
                component: () => import('@/views/Alert/NoAlertPage.vue'),
            },

            // Alert
            {
                path: 'alert-infos',
                component: () => import('@/views/Alert/AlertInfosPage.vue'),
            },
            {
                path: 'alert-map',
                component: () => import('@/views/Alert/AlertMapPage.vue'),
            },
            {
                path: 'alert-meeting',
                component: () => import('@/views/Alert/AlertMeetingPage.vue'),
            },
            {
                path: 'alert-actions',
                component: () => import('@/views/Alert/AlertActionsPage.vue'),
            },
            {
                path: 'alert-history',
                component: () => import('@/views/Alert/AlertHistoryPage.vue'),
            },
            // 404
            {
                path: ':pathMatch(.*)*', // 404
                redirect: '/tabs/no-alert',
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*', // 404
        redirect: '/tabs/no-alert',
    },
];

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach(async (to) => {
    const userStore = useUserStore();
    const alertStore = useAlertStore();

    if (onlyLoggedOut.indexOf(to.path) >= 0 && userStore.user) {
        await router.replace('/tabs/no-alert');
    } else if (onlyLoggedIn.indexOf(to.path) >= 0 && !userStore.user) {
        await router.replace('/login');
    } else if (onlyActiveAlert.indexOf(to.path) >= 0 && !alertStore.alert) {
        await router.replace('/tabs/no-alert');
    } else if (onlyNoAlert.indexOf(to.path) >= 0 && alertStore.alert) {
        await router.replace('/tabs/alert-infos');
    }
});

export default router;
