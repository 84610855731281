import { UserLogin, UserResetPassword } from '@/types/User';
import axios from './index';
import endpointGetUser from '@/endpoints/endpointGetUser';
import endpointPostLogin from '@/endpoints/endpointPostLogin';
import endpointPostLogout from '@/endpoints/endpointPostLogout';
import endpointPostForgotPassword from '@/endpoints/endpointPostForgotPassword';
import endpointPostResetPassword from '@/endpoints/endpointPostResetPassword';

export async function postLogin(credentials: UserLogin) {
    return await axios[endpointPostLogin.method](endpointPostLogin.url, credentials);
}

export async function fetchUser() {
    return await axios[endpointGetUser.method](endpointGetUser.url);
}

export async function postLogout() {
    return await axios[endpointPostLogout.method](endpointPostLogout.url);
}

export async function postForgotPassword(email: string) {
    return await axios[endpointPostForgotPassword.method](endpointPostForgotPassword.url, { email });
}

export async function postResetPassword(data: UserResetPassword) {
    return await axios[endpointPostResetPassword.method](endpointPostResetPassword.url, data);
}
