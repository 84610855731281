import { faker } from '@faker-js/faker';
import { fakeTimestamps, Timestamped } from '@/types/Common';
import i18n from '@/i18n';
import isFactoryRandom from '@/mocks/isFactoryRandom';

/* eslint-disable */
export enum ACTION_CONFIGURATION_EFFECT {
    NEUTRAL = 'neutral',
    DISMISS = 'dismiss',
    TREAT = 'treat',
}

export interface CommentableActionConfiguration extends Timestamped {
    is_comment_mandatory: boolean;
}
export interface ActionConfiguration extends Timestamped {
    id: number;
    name: string;
    instructions?: string;
    effect: ACTION_CONFIGURATION_EFFECT;
    commentable_action_configuration?: CommentableActionConfiguration;
}

export function fakeCommentableActionConfiguration(): CommentableActionConfiguration {
    return {
        is_comment_mandatory: isFactoryRandom ? faker.datatype.boolean() : true,
        ...fakeTimestamps(),
    };
}

export function fakeActionConfiguration(existingActions?: ActionConfiguration[]) {
    const allNames = [
        'action_call_ambulance',
        'action_call_police',
        'action_confirm_resolution',
        'action_stop_vehicle',
        'action_report_site',
        'action_open_windows',
        'action_switch_off_humidifier',
        'action_switch_on_humidifier',
        'action_stop_air_conditioning',
        'action_air_conditioning_already_activated',
        'action_reduce_air_conditioning',
        'action_increase_air_conditioning',
    ];

    let randomName: string = i18n.global.t(isFactoryRandom ? faker.helpers.arrayElement(allNames) : 'action_increase_air_conditioning');
    if (existingActions) {
        const usedNames: string[] = existingActions.length > 0 ? existingActions.map((action: ActionConfiguration) => action.name) : [];

        const availableNames = allNames.filter((name) => !usedNames.includes(i18n.global.t(name)));

        randomName = isFactoryRandom ? faker.helpers.arrayElement(availableNames) : 'action_increase_air_conditioning';
    }

    const effect = isFactoryRandom ? faker.helpers.arrayElement(Object.values(ACTION_CONFIGURATION_EFFECT)) : ACTION_CONFIGURATION_EFFECT.TREAT;
    return {
        id: isFactoryRandom ? faker.number.int(100) : 1,
        name: i18n.global.t(randomName),
        instructions: i18n.global.t('action_instructions'),
        effect,
        commentable_action_configuration: fakeCommentableActionConfiguration(),
        ...fakeTimestamps(),
    };
}

export function fakeActionsConfiguration () {
    const number_action_configurations: number = faker.number.int({ min: 3, max: 5 });

    const action_configurations: ActionConfiguration[] = [];

    if (isFactoryRandom) {
        for (let i: number = 0; i < number_action_configurations; i++){
            action_configurations.push(fakeActionConfiguration(action_configurations));
        }
    } else {
        action_configurations.push(fakeActionConfiguration());
    }

    return action_configurations;
}
