import { faker } from '@faker-js/faker';
import isFactoryRandom from '@/mocks/isFactoryRandom';

export interface User {
    id?: number;
    name: string;
    email?: string;
    password?: string;
    password_confirmation?: string;
    token?: string;
    passkey?: number;
}

export interface UserLogin extends Pick<User, 'email' | 'password'> {}
export interface UserResetPassword extends Pick<User, 'email' | 'password' | 'password_confirmation' | 'token'> {}

export function factoryUser(): User {
    return {
        id: isFactoryRandom ? faker.number.int() : 1,
        name: isFactoryRandom ? faker.person.fullName() : 'John Doe',
        email: isFactoryRandom ? faker.internet.email() : 'johndoe@gmail.com',
        password: isFactoryRandom ? faker.internet.password() : 'password',
        password_confirmation: isFactoryRandom ? faker.internet.password() : 'password',
        token: isFactoryRandom ? faker.number.int({ min: 1000, max: 9999999 }).toString() : '2900970162509863',
        passkey: isFactoryRandom ? faker.number.int({ min: 1000, max: 9999 }) : 1234,
    };
}
