import { faker } from '@faker-js/faker';
import isFactoryRandom from '@/mocks/isFactoryRandom';

/* eslint-disable */
export enum NUMERIC_TRIGGER_OPERATOR {
    LT = '<',
    GT = '>',
    LTE = '<=',
    GTE = '>=',
    EQ = '=',
    BETWEEN = '<>',
}

export default interface NumericTrigger {
    max_value: number | null;
    value: number;
    operator: NUMERIC_TRIGGER_OPERATOR;
}

export function fakeNumericTrigger(): NumericTrigger {
    return {
        max_value: isFactoryRandom ? faker.number.int(100) : 100,
        value: isFactoryRandom ? faker.number.int(100) : 30,
        operator: isFactoryRandom ? faker.helpers.arrayElement(Object.values(NUMERIC_TRIGGER_OPERATOR)) : NUMERIC_TRIGGER_OPERATOR.GT,
    };
}
