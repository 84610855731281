import { faker } from '@faker-js/faker';
import isFactoryRandom from '@/mocks/isFactoryRandom';

export default interface Site {
    name: string;
}

export function fakeSite(): Site {
    return {
        name: isFactoryRandom ? faker.helpers.arrayElement(['Belle idée', 'Meyrin']) : 'Belle idée',
    };
}
