export enum METHOD {
    GET = 'get',
    POST = 'post',
    DELETE = 'delete',
}

export default interface Endpoint {
    method: METHOD;
    url: string;
}
