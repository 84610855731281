import { faker } from '@faker-js/faker';

export interface Timestamped {
    deleted_at?: string;
    created_at: string;
    updated_at?: string;
}

export function fakeTimestamps(): Timestamped {
    return {
        deleted_at: undefined,
        created_at: faker.date.past().toString(),
        updated_at: faker.date.past().toString(),
    };
}
