import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useOnline } from '@vueuse/core';
import { presentToast } from '@/utils/presentToast';
import { AxiosError } from 'axios';
import { getToken, onMessage, MessagePayload, deleteToken, isSupported } from 'firebase/messaging';
import { Device } from '@capacitor/device';
import { sendTokenForUser } from '@/api/notification';
import { useAlertStore } from '@/store/useAlertStore';
import i18n from '@/i18n';
import { useMeetingStore } from '@/store/useMeetingStore';
import { messaging } from '@/firebase';

export const useGlobalStore = defineStore('global', () => {
    const isOnline = useOnline();
    const isLoading = ref(false);
    const isNotificationInstalling = ref(false);
    const error = ref<Error | null>(null);
    const isReady = ref(false);
    const alertStore = useAlertStore();
    const meetingStore = useMeetingStore();
    const { t } = i18n.global;

    const apiCall = async (fn: () => Promise<void>) => {
        if (!isOnline.value) {
            error.value = new Error('No internet connection');
            return;
        }
        isReady.value = true;
        isLoading.value = true;
        try {
            await fn();
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                error.value = e;
                if (!e.response?.data?.message) return;
                await presentToast(e.response?.data?.message, 'danger');
            }
        } finally {
            isLoading.value = false;
        }
    };

    async function registerReady(
        swScript: string,
        options: RegistrationOptions
    ): Promise<ServiceWorkerRegistration | undefined> {
        try {
            const reg = await navigator.serviceWorker.register(swScript, options);

            // If there is an active worker and nothing incoming, return the registration.
            const incomingSw = reg.installing || reg.waiting;
            if (reg.active && !incomingSw) {
                return reg;
            }

            // If not, wait for the newest service worker to become activated.
            return new Promise<ServiceWorkerRegistration | undefined>((fulfill, reject) => {
                if (!incomingSw) {
                    return reject(new Error('No incoming service worker found.'));
                }

                incomingSw.onstatechange = (evt) => {
                    const target = evt?.target as ServiceWorker | null;
                    if (target && target.state === 'activated') {
                        incomingSw.onstatechange = null;
                        fulfill(reg);
                    }
                };
            });
        } catch (error) {
            console.error('Service Worker registration failed:', error);
            throw error;
        }
    }

    const checkNotificationPermission = async () => {
        const supported = await isSupported();

        if (!supported) {
            await presentToast(t('notificationsNotSupported'), 'danger');
            return;
        }

        if (Notification.permission === 'denied') {
            await presentToast(t('permissionsNotGranted'), 'danger');
            await Notification.requestPermission();
            return;
        }

        if (Notification.permission !== 'granted') {
            const permission = await Notification.requestPermission();
            if (permission !== 'granted') {
                await presentToast(t('permissionsNotGranted'), 'danger');
                return;
            }
        }
    };

    const setFirebaseToken = async () => {
        try {
            await checkNotificationPermission();

            isNotificationInstalling.value = true;
            const serviceWorkerRegistration = await registerReady('/firebase-messaging-sw.js', {
                scope: '/firebase-cloud-messaging-push-scope',
            });
            const token = await getToken(messaging, {
                vapidKey: import.meta.env.VITE_FIREBASE_PUBLIC_VAPID_KEY,
                serviceWorkerRegistration,
            });

            const deviceID = await Device.getId();
            if (token && deviceID.identifier) {
                await apiCall(async () => {
                    await sendTokenForUser(token, deviceID.identifier);
                });
            } else {
                await deleteToken(messaging);
            }
        } catch (err) {
            console.error('An error occurred while retrieving token. ', err);
            await deleteToken(messaging);
        }
        isNotificationInstalling.value = false;
    };

    onMessage(messaging, async (payload: MessagePayload) => {
        const alertId = payload.data?.alert_id as number | undefined;
        const meetingRoomName = payload.data?.meeting_room_name as string | undefined;
        const meetingId = payload.data?.meeting_id as string | undefined;

        if (alertId) {
            await alertStore.getAlert(alertId);
        }

        if (meetingRoomName && meetingId) {
            meetingStore.setMeeting(meetingId, meetingRoomName);
        }

        const title = payload.notification?.title;
        const body = payload.notification?.body;

        if (!title || !body) return;

        await presentToast(body, 'primary', title);
    });

    return {
        apiCall,
        isLoading,
        error,
        isReady,
        isOnline,
        setFirebaseToken,
        checkNotificationPermission,
        isNotificationInstalling,
    };
});
