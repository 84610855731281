import { faker } from '@faker-js/faker';
import Site, { fakeSite } from '@/types/Site';
import AlertSetting, { fakeAlertSetting } from '@/types/AlertSetting';
import { fakeTimestamps, Timestamped } from '@/types/Common';
import { fakeVehicle, Vehicle } from '@/types/Vehicle';
import { ActionConfiguration, fakeActionConfiguration } from '@/types/ActionConfiguration';
import { factoryUser, User } from '@/types/User';
import i18n from '@/i18n';
import isFactoryRandom from '@/mocks/isFactoryRandom';

export enum STATUS_ALERT {
    PENDING = 'pending',
    RESOLVED = 'resolved',
    IGNORED = 'ignored',
}

export interface Alert extends Timestamped {
    id: number;
    name: string;
    color: string;
    value?: number;
    location: GeoJson;
    unit?: string;
    vehicle: Vehicle;
    site: Site;
    alert_setting: AlertSetting;
    status: 'pending' | 'resolved' | 'ignored';
    alert_logs_by_day: AlertLogsByDay[];
}
export interface GeoJson {
    type: string;
    coordinates: number[];
    properties: {
        address?: string | null;
    };
}

export interface AlertLogsByDay {
    date: Date;
    logs: AlertLog[];
}

export function factoryAlert(): Alert {
    const allNames = ['event_passengers', 'event_temperature', 'event_air_humidity'];

    const name = isFactoryRandom ? faker.helpers.arrayElement(allNames) : 'event_temperature';

    return {
        id: 1,
        name: i18n.global.t(name),
        value: 35,
        color: isFactoryRandom ? faker.internet.color() : '#1f00ff',
        unit:
            name === 'event_temperature' ? '°C' : name === 'event_passengers' ? i18n.global.t('unit_passengers') : '%',
        location: {
            type: 'Point',
            coordinates: [
                isFactoryRandom ? faker.location.latitude() : 6.1432,
                isFactoryRandom ? faker.location.longitude() : 46.2044,
            ],
            properties: {
                address: isFactoryRandom
                    ? faker.location.streetAddress()
                    : 'Rte François-Peyrot 30, 1218 Le Grand-Saconnex, Switzerland',
            },
        },
        status: 'pending',
        site: fakeSite(),
        vehicle: fakeVehicle(),
        alert_setting: fakeAlertSetting(),
        alert_logs_by_day: fakeAlertLogsByDays(),
        ...fakeTimestamps(),
    };
}

export enum ALERT_LOGGABLE_TYPES {
    ACTION = 'App\\Models\\Action',
    ALERT_EVENT = 'App\\Models\\AlertEvent',
}
export interface Actionable extends Timestamped {
    id: number;
    comment: string;
}

export interface AlertLog {
    id: number;
    isLast?: boolean;
    value?: number | null;
    vehicle_long?: number | null;
    vehicle_lat?: number | null;
    type: ALERT_LOGGABLE_TYPES;
    action_configuration?: ActionConfiguration;
    user?: User;
    actionable?: Actionable;
    created_at: string;
}

export function fakeAlertLog(date = faker.date.past().toString(), selectedType?: ALERT_LOGGABLE_TYPES): AlertLog {
    const type = selectedType || faker.helpers.arrayElement(Object.values(ALERT_LOGGABLE_TYPES));
    return {
        id: faker.number.int(100),
        value: type === ALERT_LOGGABLE_TYPES.ACTION ? null : faker.number.int(100),
        type: type,
        action_configuration: fakeActionConfiguration(),
        user: factoryUser(),
        created_at: date,
    };
}

export function fakeAlertLogsByDays(): AlertLogsByDay[] {
    const numberOfDays = faker.number.int({ min: 3, max: 5 });
    const dates = new Set<string>();

    while (dates.size < numberOfDays) {
        dates.add(faker.date.recent(10).toISOString().split('T')[0]);
    }

    let alertLogsByDays: { date: Date; logs: AlertLog[] }[] = Array.from(dates).map((date) => ({
        date: new Date(date),
        logs: [],
    }));

    alertLogsByDays = alertLogsByDays.sort((a, b) => b.date.getTime() - a.date.getTime());

    // add event logs for all new logs, but if it's the last log of the last day: randomize between action and event
    alertLogsByDays.forEach((day, indexDay) => {
        const numberOfLogs = faker.number.int({ min: 1, max: 3 });
        for (let indexLog = 0; indexLog < numberOfLogs; indexLog++) {
            const isFirstLogOfFirstDay = indexDay === 0 && indexLog === 0;
            const logType = isFirstLogOfFirstDay
                ? faker.helpers.arrayElement(Object.values(ALERT_LOGGABLE_TYPES))
                : ALERT_LOGGABLE_TYPES.ALERT_EVENT;

            const log: AlertLog = fakeAlertLog(faker.date.past().toString(), logType as ALERT_LOGGABLE_TYPES);
            day.logs.push(log);
        }
    });

    return alertLogsByDays;
}
