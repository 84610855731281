<script setup lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import router from '@/router';
import { computed, onMounted, watch } from 'vue';
import { useAlertStore } from '@/store/useAlertStore';
import MoleculeReloadPrompt from '@/components/Molecules/MoleculeReloadPrompt.vue';
import { App } from '@capacitor/app';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/useUserStore';
import useAction from '@/Composables/useAction';
import { useMeetingStore } from '@/store/useMeetingStore';
import { useGlobalStore } from '@/store/useGlobalStore';
const alertStore = useAlertStore();
const meetingStore = useMeetingStore();
const globalStore = useGlobalStore();
const userStore = useUserStore();
const { user } = storeToRefs(useUserStore());
const onlyNoAlert: string[] = ['/tabs/no-alert'];
const onlyActiveAlert: string[] = [
    '/tabs/alert-infos',
    '/tabs/alert-map',
    '/tabs/alert-actions',
    '/tabs/alert-history',
];

const alert = computed(() => {
    return alertStore.alert;
});

onMounted(async () => {
    const jitsiAppId = import.meta.env.VITE_JITSI_APP_ID;
    if (jitsiAppId) {
        const script = document.createElement('script');
        script.src = `https://8x8.vc/${jitsiAppId}/external_api.js`;
        document.head.appendChild(script);
    } else {
        console.error('JITSI_APP_ID is not defined');
    }

    if (user.value) {
        // Check if the user is logged in
        await userStore.getUser();
        await globalStore.checkNotificationPermission();
    }
});

watch(
    [alert, router.currentRoute],
    () => {
        if (onlyActiveAlert.indexOf(router.currentRoute.value.fullPath) >= 0 && !alert.value) {
            useAction().clearSelectedActionConfiguration();
            meetingStore.closeMeeting();
            router.replace('/tabs/no-alert');
        } else if (onlyNoAlert.indexOf(router.currentRoute.value.fullPath) >= 0 && alert.value) {
            useAction().clearSelectedActionConfiguration();
            router.replace('/tabs/alert-infos');
        }
    },
    { deep: true }
);

App.addListener('appStateChange', ({ isActive }) => {
    if (isActive && user.value && alert.value) {
        // app is in foreground, so visible by the user
        alertStore.getAlert(alert.value?.id);
    }
});

navigator.serviceWorker.onmessage = (event) => {
    if (event.data.messageType === 'notification-clicked') {
        const alertId = event.data.data?.alert_id ?? null;
        const meetingId = event.data.data?.meeting_id ?? null;
        const meetingRoomName = event.data.data?.meeting_room_name ?? null;
        if (alertId) {
            alertStore.getAlert(alertId);
            meetingStore.setMeeting(meetingId, meetingRoomName);
        }
    }
};
</script>

<template>
    <MoleculeReloadPrompt />
    <IonApp>
        <IonRouterOutlet />
    </IonApp>
</template>
