import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import * as Sentry from '@sentry/vue';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import ripple from '@/directives/ripple';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { IonicVue } from '@ionic/vue';
import { useUserStore } from '@/store/useUserStore';
import { useAlertStore } from '@/store/useAlertStore';
const IS_MOCKING_ENABLED = import.meta.env.VITE_MOCKING_ENABLED === 'true';

/* tailwind css */
import './assets/css/style.css';
/* symbol material */
import './assets/css/style.scss';
import router from '@/router';
import i18n from '@/i18n';
import './mapbox';
import './dayjs';
import enableMirageAPIMocking from '@/mocks/enableMirageAPIMocking';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

if (!IS_MOCKING_ENABLED) {
    const intervalMS = 60 * 60 * 1000;
    useRegisterSW({
        onRegistered(r) {
            r &&
                setInterval(() => {
                    r.update();
                }, intervalMS);
        },
    });
}

const app = createApp(App).use(IonicVue).use(pinia).use(router).use(i18n).directive('ripple', ripple);

const userStore = useUserStore();
const alertStore = useAlertStore();

userStore.$hydrate();
alertStore.$hydrate();

if (process.env.NODE_ENV === 'development' && IS_MOCKING_ENABLED) {
    enableMirageAPIMocking();
}

router.isReady().then(() => {
    app.mount('#app');
});

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        app,
        dsn: 'https://e1f88f2c4dd8e835ef7da606199b4463@sentry.mobilethinking.ch/3',
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        replaysOnErrorSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        replaysSessionSampleRate: 0.1,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        tracePropagationTargets: ['localhost', /^https:\/\/ultimo\.app\.+.\.mobilethinking\.ch/],
        tracesSampleRate: 1.0,
    });
}
