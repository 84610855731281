<script setup lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonPage, IonRouterOutlet } from '@ionic/vue';
import { computed, reactive, watch } from 'vue';
import InfosIcon from '@/components/Atoms/Icons/InfosIcon.vue';
import MapIcon from '@/components/Atoms/Icons/MapIcon.vue';
import ActionsIcon from '@/components/Atoms/Icons/ActionsIcon.vue';
import HistoryIcon from '@/components/Atoms/Icons/HistoryIcon.vue';
import { useUserStore } from '@/store/useUserStore';
import { useAlertStore } from '@/store/useAlertStore';
import { useRoute } from 'vue-router';
import { Tab } from '@/types/Tab';
import CameraIcon from '@/components/Atoms/Icons/CameraIcon.vue';
import { useMeetingStore } from '@/store/useMeetingStore';
import router from '@/router';

const userStore = useUserStore();
const alertStore = useAlertStore();
const meetingStore = useMeetingStore();
const route = useRoute();

const isLogged = computed(() => {
    return userStore.user !== null;
});

const isAlertActive = computed(() => {
    return alertStore.alert !== null;
});

const tabs = reactive<Tab[]>([
    { name: 'infos', href: '/tabs/alert-infos', icon: InfosIcon, label: 'Infos' },
    { name: 'map', href: '/tabs/alert-map', icon: MapIcon, label: 'Map' },
    { name: 'actions', href: '/tabs/alert-actions', icon: ActionsIcon, label: 'Actions' },
    { name: 'history', href: '/tabs/alert-history', icon: HistoryIcon, label: 'History' },
]);

const getHref = (tab: Tab) => {
    if (isLogged.value && isAlertActive.value) {
        return tab.href;
    } else {
        return '/tabs/no-alert';
    }
};

const getIsActive = (tabName: Tab['name']) => route.path.includes(tabName);

const tabClass = (tabName: string) => {
    const isActive = getIsActive(tabName);
    if (!isAlertActive.value) {
        return 'text-gray-300';
    } else {
        return isActive ? 'text-primary' : 'text-black';
    }
};

watch(
    () => meetingStore.meeting,
    (meeting) => {
        if (meeting) {
            const checkAlreadyExist = tabs.find((tab) => tab.name === 'meeting');
            if (!checkAlreadyExist) {
                tabs.push({ name: 'meeting', href: '/tabs/alert-meeting', icon: CameraIcon, label: 'Meeting' });
            }
            router.push('/tabs/alert-meeting');
        } else {
            const checkAlreadyExist = tabs.find((tab) => tab.name === 'meeting');
            if (checkAlreadyExist && route.path === '/tabs/alert-meeting' && alertStore.alert) {
                tabs.pop();
                router.push('/tabs/alert-infos');
            } else if (checkAlreadyExist) {
                tabs.pop();
                router.push('/tabs/no-alert');
            }
        }
    },
    { deep: true });
</script>

<template>
    <IonPage>
        <IonTabs>
            <IonRouterOutlet></IonRouterOutlet>
            <IonTabBar v-if="isLogged" slot="bottom">
                <IonTabButton
                    v-for="tab in tabs"
                    :key="tab.name"
                    :data-testid="isAlertActive ? 'tabButton' : null"
                    :tab="tab.name"
                    :href="getHref(tab)"
                >
                    <component :is="tab.icon" class="fill-current w-9 h-9" :class="tabClass(tab.name)" />
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    </IonPage>
</template>

<style>
ion-tab-bar {
    box-shadow: 0px -7px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 0px;
}
</style>
