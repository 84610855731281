import { defineStore } from 'pinia';
import { useGlobalStore } from '@/store/useGlobalStore';
import { ref } from 'vue';

import { Alert } from '@/types/Alert';
import { fetchAlert, fetchAlertAssociated, postAction } from '@/api/alert';
import Meeting from '@/types/Meeting';
import { useMeetingStore } from '@/store/useMeetingStore';

export const useAlertStore = defineStore(
    'alert',
    () => {
        const alert = ref<Alert | null>(null);
        const { setMeeting, closeMeeting } = useMeetingStore();

        const getCurrentAlertAssociated = async () => {
            const { apiCall } = useGlobalStore();
            await apiCall(async () => {
                try {
                    const { data } = await fetchAlertAssociated();
                    const alert = data.alert as Alert;
                    const meeting = data.meeting as Meeting | null;
                    setAlert(alert);
                    if (meeting) {
                        setMeeting(meeting.id, meeting.room_name);
                    } else {
                        closeMeeting();
                    }
                } catch (e) {
                    setAlert(null);
                    closeMeeting();
                }
            });
        };

        const getAlert = async (id: number) => {
            const { apiCall } = useGlobalStore();
            await apiCall(async () => {
                const { data } = await fetchAlert(id);
                setAlert(data as Alert);
            });
        };

        const setAlert = (newAlert: Alert | null) => {
            if (newAlert && newAlert.status === 'pending') {
                alert.value = newAlert;
            } else {
                alert.value = null;
                closeMeeting();
            }
        };

        const sendAction = async ({ alertId, actionConfigurationId, comment }) => {
            const { apiCall } = useGlobalStore();
            await apiCall(async () => {
                await postAction(alertId, actionConfigurationId, comment);
            });
        };

        return {
            alert,
            getAlert,
            setAlert,
            sendAction,
            getCurrentAlertAssociated,
        };
    },
    { persist: true }
);
