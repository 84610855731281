import { faker } from '@faker-js/faker';
import isFactoryRandom from '@/mocks/isFactoryRandom';

export interface Vehicle {
    identifier: string;
}

export function fakeVehicle(): Vehicle {
    return {
        identifier: isFactoryRandom ? faker.helpers.arrayElement(['Vehicle 1', 'Vehicle 2', 'Vehicle 3']) : 'Vehicle 1',
    };
}
